<template>
    <div class="ResponsiveContentBoard">
        <div class="btnAreaCenter">
            <router-link v-if="userAccessLevel != accessLevel.PRODUCAO" class="btnExtraLargeMenu" exact to="/Setting/Users">
                <img class="iconBtnExtraLarge" src="~@/assets/icons/Setting/user.svg" alt="">
                <div class="textNormal">Usuários</div>
            </router-link>
            <router-link v-if="userAccessLevel != accessLevel.PRODUCAO" class="btnExtraLargeMenu" to="/Setting/Clients">
                <img class="iconBtnExtraLarge" src="~@/assets/icons/Setting/client.svg" alt="">
                <div class="textNormal">Clientes</div>
            </router-link>
            <router-link v-if="userAccessLevel != accessLevel.PRODUCAO" class="btnExtraLargeMenu" to="/Setting/Unitys">
                <img class="iconBtnExtraLarge" src="~@/assets/icons/Setting/factory.svg" alt="">
                <div class="textNormal">Unidades</div>
            </router-link>
            <!--router-link class="btnExtraLargeMenu" to="/Setting/License">
                <img class="iconBtnExtraLarge" src="~@/assets/icons/Setting/license.svg" alt="">
                <div class="textNormal">Licenças</div>
            </router-link-->
            <router-link class="btnExtraLargeMenu" to="/Setting/Devices">
                <img class="iconBtnExtraLarge" src="~@/assets/icons/Setting/device.svg" alt="">
                <div class="textNormal">Dispositivos</div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import BackEnd from "@/sys/BackEnd/BanckEnd";
    import {AccessLevel} from '@/sys/Model/Permissions/AccessLevel'

    export default {
        data() {
            return {
                accessLevel: AccessLevel,
                userAccessLevel: BackEnd.getCurrentUserAccess()
            }
        },
        props: {
        }
    }
</script>

<style scoped>
    .ResponsiveContentBoard{
        position: relative;
    }
    .btnAreaCenter{
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        height: 720px;
        width: 1250px;
        margin: auto auto
    }
    @media(max-width: 1600px){
        .btnExtraLargeMenu{
            height: 220px;
            width: 220px;
        }
        .btnAreaCenter{
            height: 500px;
            width: 925px;
        }
    }
    @media(max-height: 720px){
        .btnExtraLargeMenu{
            height: 220px;
            width: 220px;
        }
        .btnAreaCenter{
            height: 500px;
            width: 925px;
        }
    }
    @media(max-height: 500px) and (min-width: 825px){
        .btnAreaCenter{
            margin: 20px auto;
        }
    }
    @media(max-width: 1200px){
        .btnExtraLargeMenu{
            margin: 0 20px 25px 20px;
            height: 220px;
            width: 220px;
        }
        .btnAreaCenter{
            height: 500px;
            width: 525px;
        }
    }
    @media(max-width: 825px){
        .btnExtraLargeMenu{
            margin: 0 0 25px 6%;
            height: 130px;
            width: 40%;
        }
        .btnAreaCenter{
            height: 80%;
            width: 100%;
        }
    }
    @media (orientation: portrait) {
        .btnExtraLargeMenu{
            margin: 0 0 25px 6%;
            height: 200px;
            width: 40%
        }
        .btnAreaCenter{
            height: 80%;
            width: 100%;
        }
    }
    @media(max-width: 500px){
        .btnExtraLargeMenu{
            height: 130px;
        }
    }
</style>